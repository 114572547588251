import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Comite from '../components/Comite'
import Form from '../components/Form'

const Contact = props => (
  <Layout>
    <Helmet>
      <title>Contact - Palette Sartoise</title>
      <meta name="description" content="Contact Page" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Contact</h2>
          <p>
          Pour toutes questions sur la Palette Sartoise, n’hésitez pas à nous contacter par téléphone ou via le formulaire ci-desous. <br/>
          Nous nous ferons un plaisir de vous répondre.          
          </p>
        </header>
      <Comite />
      <Form />

      </div>
    </div>
  </Layout>
)

export default Contact
