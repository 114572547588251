import React from 'react'

const Form = props => (
<header className="major">

<section>
          <h3>Formulaire</h3>
            <form name="contact" method="post" action="https://formspree.io/jonathan.rousseaux@gmail.com">
            
            <div className="row gtr-uniform gtr-50">
              <div className="col-6 col-12-xsmall">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-xsmall">
                <input
                  type="email"
                  name="_replyto"
                  id="email"
                  placeholder="Email"
                />
              </div>
               <div className="col-12">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Enter your message"
                  rows="6"
                ></textarea>
              </div>
              <div className="col-12">
                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      value="Send Message"
                      className="primary"
                    />
                  </li>
                  <li>
                    <input type="reset" value="Reset" />
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </section>


</header>
)

export default Form
