import React from 'react'


const Comite = props => (
<header className="major">
    <section id="comite">
      <div className="container">
        <h3>Le comité de la Palette Sartoise</h3>
        <div className="box alt">
          <div className="row gtr-uniform">
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-crown"></span>
              <h4>Stephane Draux</h4>
              <h5>Président</h5>
              <p>
                Le leader charismatique (sic) de la Palette Sartoise. Il est actif à la fois en coulisse, à la table mais aussi et surtout devant et derrière le bar.
              </p>
              <p>
                <a href="tel:+32 486 462566">0486/46.25.66</a>
               </p>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-user-secret"></span>
              <h4>Olivier Capelle</h4>
              <h5>Secrétaire</h5>
              <p>
                L'homme de l'ombre.
              </p>
              <p>
                <a href="tel:+32 475 470121 ">0475/47.01.21</a>
              </p>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-table-tennis"></span>
              <h4>Alain Bohon</h4>
              <h5>Responsable interclubs</h5>
              <p>
                Le stratège de la Palette Sartoise, celui qui compose et optimise les équipes chaque weekend afin de nous assurer de précieuses victoires (ou pas)!
              </p>
              <p>
                <a href="tel:+32 479 534140">0479/53.41.40</a>
              </p>
            </section>
            <section className="col-3 col-6-medium col-12-xsmall">
              <span className="icon solid alt major fa-globe"></span>
              <h4>Jonathan Rousseaux</h4>
              <h5>Webmaster</h5>
              <p>
                Ancien joueur, Sartois exilé à Bruxelles, informaticien, <a href="https://www.jr-photo.be" target="_blank">photographe</a> et webmaster du site web de la Palette Sartoise.
              </p>
              <p>  
                <ul className="icons">
                <li>
                  <a href="https://www.linkedin.com/in/jrousseaux" className="icon brands fa-linkedin" target="_blank">
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/jonathan.rousseaux" className="icon brands fa-facebook-f" target="_blank">
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jr_phototravel" className="icon brands fa-instagram" target="_blank">
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.jr-photo.be" className="icon brands fa-chrome" target="_blank">
                    <span className="label">Photographe</span>
                  </a>
                </li>
                </ul>
               </p>
            </section>
       
          </div>
        </div>
      </div>
    </section>
</header>
)

export default Comite
